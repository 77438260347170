.upgrade-ad .upgrade-banner {
    position:relative;
    width: 100%;
    font-size: 1.1rem;
    color: #000;
    margin: auto;
    padding: 1rem;
    background-color: #ECF1F7;
}
form .upgrade-ad {
  width: 100%;
}
.upgrade-ad .upgrade-sidebar {
  color: #fff;
}
.upgrade-ad .upgrade-button, .upgrade-ad .upgrade-button-2 {
  color: #fff !important;
  background-color: #ec755a !important;
  border-color: #ec755a;
  font-size: 1.25rem;
}
.upgrade-ad .upgrade-button {
  margin-right:10px;
  margin-top:5px;
}
.upgrade-ad .upgrade-button-2 {
  padding-left:30px;
  padding-right:30px;
}
.upgrade-ad .upgrade-sidebar-top {
  border-top: 1px solid #fff;
  margin-right: 35px;
  padding-bottom: 15px;
}
.upgrade-ad .closer {
    position:absolute;
    right: 7px;
    top: 0;
}
.upgrade-ad .closer a {
    color: #525252;
}
.upgrade-ad .ad-help-text-button {
  position: relative;
  margin-left: 0.25em;
}

.upgrade-ad .ad-help-text-button .ad-help-text {
  visibility: hidden;
  background: #fff;
  padding: 10px;
  z-index: 1;
  font-size: 1rem;
  line-height: 1.3;
  position: absolute;
  width: auto;
  border-radius: 3px;
  bottom: 0;
  box-shadow: 1px 1px 11px 2px #00000073;
  border: solid 1px #809bb8;
  background-color: #ffffff;
  color: #212940;
  font-weight: 400;
  left: -30px;
  transform: translate(-50%, 5%);
  -webkit-transform: translate(-50%, 5%);
}
.upgrade-ad .ad-help-text-button:hover .ad-help-text {
  visibility: visible;
}

@media (min-width: 576px) and (max-width: 1600px) {
    .upgrade-ad .upgrade-button {
        font-size: 1rem;
    }
}
.greenColor {
  background-color: green;
  border-radius: 2px;
  border: solid 2px #7fbf92;
  background-color: #d9f0e0;
  font-size: 0.875rem;
  width: 48px;
  cursor: pointer;
}

div .businessHours.row label.highlighted.greenColor {
  background-color: green;
  border-radius: 2px;
  border: solid 2px #7fbf92;
  background-color: #d9f0e0;
  font-size: 0.875rem;
  width: 48px;
  cursor: pointer;
}

.whiteColor {
  background-color: white;
  border-radius: 4px;
  border: solid 2px #79829f;
  font-size: 0.875rem;
  width: 48px;
  cursor: pointer;
}

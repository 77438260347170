// Mobile nav styling and overrides
@media only screen and (min-width: 0px) and (max-width: 992px) {
  .main__nav {
    background-color: #4b5f9c;
  }

  .main__nav .menu-box svg {
    color: #9bafec;
  }

  .main__nav .menu-box {
    flex-basis: 100%;
  }

  .main__nav .menu-box {
    margin-bottom: 5px;
  }

  .main nav.navbar ul {
    width: 100%;
  }

  .container {
    width: inherit;
  }

  .gmaps-image-wrapper img {
    width: 100%;
  }

  div.alert-wrapper {
    display: inherit;
  }

  div.alert-wrapper {
    position: relative;
    z-index: -2;
  }

  .profile-edit-nav li {
    flex-basis: 100%;
  }

  .active .sticky-inner-wrapper {
    display: none;
  }

  .main__header {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .main__header .centered-container {
    text-align: left;
  }

  .main__header .navbar-brand {
    display: none;
  }

  .main__header div.dropdown {
    padding-right: 0 !important;
  }

  .Toastify__toast-container {
    padding: 5px;
  }

  .main__nav .nav-item {
    padding-left: 1.75rem;
  }
}

.profile-state .green {
  color: #7fbf92;
}

.profile-state .red {
  color: #ec755a;
}

.profile-state .grey {
  color: #a3a3a3;
}

.profile-state .black {
  color: black;
}

.larger-font {
  font-size: 1.1875rem;
}

.navbar-background {
  background-image: linear-gradient(186deg, #4b5f9c 106%, #132455 9%);
}

.main__nav a {
  font-family: Lato;
  font-size: 1.125rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e7eef8;
}
.main__nav a.admin:hover,
.main__nav a.admin:active {
  color: #e7eef8;
}

@media only screen and (max-width: 992px) {
  .full-size {
    width: 100%;
    height: 35%;
  }
}
@media only screen and (max-width: 400px) {
  .full-size {
    width: 100%;
    height: 0%;
  }
}
// Desktop nav styling
@media only screen and (min-width: 993px) {
  .main__header {
    grid-area: header;
    display: flex;
    justify-content: center;
    font-family: Lato;
    background-color: #feff00;
    text-align: center;
  }

  .main__nav {
    width: 195px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    grid-area: nav;
    font-family: Lato;
    background-image: linear-gradient(186deg, #4b5f9c 106%, #132455 9%);
  }

  .main__content {
    display: flex;
    justify-content: center;
    grid-area: main;
    overflow: scroll;
    font-family: Lato;
  }

  //grid declaration
  div.main {
    display: grid;
    position: relative;
    height: 100vh;
    background-color: #ffffff;
    grid-template-areas: "nav main";
    grid-template-columns: 195px 1fr;
    grid-template-rows: 1fr;
  }

  div.main.top-banner {
    display: grid;
    position: relative;
    height: 100vh;
    background-color: #ffffff;
    grid-template-areas:
      "header header"
      "nav main";
    grid-template-columns: 300px 1fr;
    grid-template-rows:
      40px
      1fr;
  }

  .main__nav li {
    width: 100%;
  }

  .main__nav .nav-item {
    padding-left: 1rem;
  }

  .main__nav a.navbar-brand {
    font-size: 1.125rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.98px;
    color: #ffffff;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .full-size {
    width: 79%;
    height: 100%;
  }
}

@media only screen and (min-width: 1401px) {
  .main__nav {
    width: 300px;
  }
  .main__nav .nav-item {
    padding-left: 1.75rem;
  }

  //grid declaration
  div.main {
    //grid
    grid-template-columns: 300px 1fr;
  }

  .full-size {
    width: 79%;
    height: 100%;
  }
}

.lazyloaded {
  z-index: 200;
}

nav div .lh-22 {
  line-height: 22px;
}

.nav-tabs .nav-link {
  border: none;
}

div button.btn-small {
  min-height: 30px;
}

.btn-primary {
  border-color: #4b5f9c !important;
  background-color: #4b5f9c !important;
  color: white !important;
  font-weight: 600 !important;
  border: 2px solid;
  min-height: 44px;
}
.btn-primary:hover {
  border-color: #4b5f9c !important;
  background-color: #546bb3 !important;
  color: white !important;
  min-height: 44px;
}

.btn-primary.small-btn {
  height: 38px;
}

.btn-secondary {
  color: #4b5f9c !important;
  background-color: white !important;
  font-weight: 400 !important;
  border: 2px solid;
  min-height: 44px;
}
button.white-button.btn-secondary:hover,
button.white-button.btn-secondary:active {
  color: #fff !important;
  background-color: #6c757d !important;
  min-height: 44px;
}
.btn.btn-secondary.disabled,
.btn.btn-secondary:disabled {
  border-color: #4b5f9c;
}
.btn.btn.disabled,
.btn.btn:disabled {
  opacity: 0.3;
}
.btn-profile-edit {
  font-size: 1.25rem;
}
.show > .toggle.btn-secondary,
.toggle.btn-secondary {
  color: #212940 !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.btn-secondary:focus,
.btn-secondary.focus .btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.btn-primary:focus,
.btn-primary.focus .btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

a,
.nav-link {
  color: #000000;
  font-weight: bold;
  border: none;
}

form .row a.nav-link {
  font-weight: normal;
  padding-bottom: 0;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

form .row li.nav-item {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  margin-bottom: 0.4rem;
}

.blue-header {
  color: #4b5f9c;
}

.border-transparent {
  border-color: transparent !important;
}

.background-transparent {
  background-color: transparent !important;
}
.btn-primary.background-transparent:hover {
  border-color: transparent !important;
  background-color: transparent !important;
}

.nav-link:hover,
a :not(.btn):hover {
  border: none;
}

.nav-link.active {
  color: #4b5f9c;
  border: none;
}

.alert ul {
  margin-bottom: 0;
}

.regular-font {
  font-size: 1rem !important;
}

form div .alert-danger {
  color: #dd2934;
  background-color: #f3d8da;
  border-color: #f5c6cb;
}

.main__nav div .lh-22 {
  line-height: 22px;
}

.main__nav li a label {
  color: #fff;
}

.main__nav li.active-link a label {
  color: #000000;
}

.main__nav li.active-link {
  background-color: #edeff5;
}

.main__nav li.active-link a label {
  color: #000000;
}

.main__nav li .menu-icon {
  fill: #fff;
}

.main__nav li.active-link .menu-icon {
  fill: #4b5f9c;
}

.main__nav .nav-item svg {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
}

.title {
  font-weight: 900;
}

.alert.alert-danger ul li {
  list-style: none;
  color: #dd2934;
}

form .card-wrapper-promo.row .form-group > div.highlighted-text {
  color: red;
}

.profile-edit-nav a:hover {
  color: black;
  font-weight: bold !important;
}

.profile-edit-nav a::before {
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

table.assignment {
  border-spacing: 0 7px;
  border-collapse: separate;
}

div div .button-link {
  text-decoration: none;
  min-height: 30px;
}

form .googlePlaces input {
  margin-left: -10px !important;
}

form .googlePlaces > div > div {
  flex-wrap: nowrap
}

form .googlePlaces > div > div > div {
  padding-left: 15px;
}

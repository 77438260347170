.rdt_TableRow {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.rdt_TableCell {
  .statusIndicator {
    margin: auto;
    color: #4b5f9c;
  }
}
.image-editor-overlay {
  position: absolute;
  top: 0;
}
.image-editor-overlay > button {
  color: #fff;
  aspect-ratio: 1;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: #5388eb;
  padding: 0 0.2rem;
  height: auto;
  border: none;
  opacity: 0.6;
}
.image-editor-overlay > button:hover {
  opacity: 1;
}
.image-editor-overlay > button > svg {
  padding-left: 0.15rem;
}
.edit-image-modal {
  height: 555px;
  width: 500px;
  position:absolute;
}
.edit-image-modal .modal-footer {
  margin-top:55px;
}
.edit-image-modal .modal-footer.extra-height{
  margin-top:85px;
}
.edit-image-modal .controls-container {
  position: absolute;
  bottom: -55px;
  width:100%;
  background-color: #FAF8F7;
  opacity: 1;
  height: 55px;
  left:0;
}
.edit-image-modal .controls-container-extra {
  position: absolute;
  bottom: -85px;
  width:100%;
  height: 85px;
}
.edit-image-modal .zoom-controls {
  position:relative;
  left: 55%;
  width: 60%;
  transform: translateX(-60%);
  height: 30px;
  display: flex;
  align-items: center;
}
.edit-image-modal .zoom-controls .MuiSlider-root {
  box-sizing: border-box;
}
.edit-image-modal .rotation-controls {
  position:relative;
  left: 55%;
  width: 60%;
  transform: translateX(-60%);
  height: 25px;
  display: flex;
  align-items: center;
}
.edit-image-modal .aspect-controls {
  position:relative;
  left: 55%;
  width: 60%;
  transform: translateX(-60%);
  height: 25px;
  display: flex;
  align-items: center;
}
.edit-image-modal .slider {
  padding: 22px 0px;
  margin-left:10px;
}
.edit-image-modal .slider-label {
  min-width: 85px;
  text-align:right;
}

.edit-image-modal .update-button {
  font-size: 0.875rem;
}
.edit-image-modal .update-button:active {
  background-color: white;
}
.edit-image-modal .update-button:focus {
  background-color: white;
}
.edit-image-modal .crop-cancel-button {
  margin-left: 5px;
}

@media only screen and (max-width: 480px) {
  .edit-image-modal {
    width: 360px;
  }
}
@media only screen and (max-width: 360px) {
  .edit-image-modal {
    width: 300px;
  }
}

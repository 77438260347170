.help-profile-status {
    border-top: none;
    margin-top: 0.75rem;

    .header {
        background-color: #4b5f9c;
        color: white;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        margin-bottom: 0.5rem;
    }
}

.black-font {
  color: #212940;
}
div.empty-plan.custom-radio.custom-control label {
  border: dashed 1px #adc5de;
  min-width: 200px;
  padding: 0.25rem;
}

div .header1 label.empty-plan,
div label.bullet.empty-plan,
div span.bullet.empty-plan {
  border: dashed 1px #adc5de;
  min-width: 200px;
}

div label.empty-plan {
  border: dashed 1px #adc5de;
}

div .most-popular .empty-plan {
  border: dashed 1px white;
}

div.features-container {
  min-height: 200px;
}

.plans .empty-plan.custom-radio label {
  font-size: 0.875rem;
}

.plans .empty-plan .custom-control-label {
  font-weight: 300 !important;
}
.plans .header1 .empty-plan {
  font-size: 0.875rem;
}

.rdt_TableCell {
  .assignCampaign {
    padding-bottom: 0.5rem !important;
    cursor: pointer;
  }

  a {
    text-decoration: underline;
  }
}

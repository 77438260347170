.plans .custom-radio label {
  color: #292f42;
  font-size: 1.25rem;
  font-weight: 500;
}

.plans .header1 {
  color: #292f42;
  font-size: 1.375rem;
  font-weight: bold;
  line-height: 1.09;
}

.header2 {
  color: #212940;
  font-size: 1.125rem;
}

.plans .bullet {
  color: #292f42;
  font-size: 0.875rem;
  line-height: 1.71;
}

.plans .check {
  background-color: #5388eb;
}

.split-line {
  background-color: #c2cce8;
}
.plans .card.card-body {
  border-radius: 8px;
  border: solid 2px #adc5de;
  cursor: pointer;
}

.plans .card.card-body label,
.plans .card.card-body span {
  cursor: pointer;
}

.isSelected {
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(33, 38, 42, 0.3);
  border: solid 3px var(--soft-green);
  background-color: rgba(88, 105, 158, 0.9);
  color: #ffffff;
}

.plans .isSelected .bullet,
.plans .isSelected label,
.plans .isSelected svg {
  color: #ffffff;
}
.plans svg {
  color: #5388eb;
}

.plans .form-group {
  margin-bottom: 0 !important;
}

.plans .custom-control-label {
  line-height: 1;
  font-weight: 600 !important;
}

.plans .card-title.popular {
  display: flex;
  justify-content: space-between;
}

.plans .card-title.popular .most-popular {
  background-color: #505d83;
  color: white;
  border-radius: 2px;
  font-size: 0.6875rem;
  padding: 4px;
}

.registration-control button.btn-primary {
  height: 48px;
}

.plans .soft-green {
  color: #7fbf92;
}

form .plans .white div.highlighted-text {
  color: white;
}

@media (max-width: 991px) {
  .plans .card {
    margin: 0.875rem;
  }
}

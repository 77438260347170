.dashboard-filter button {
  background-color: #fff;
  color: black;
  font-weight: 400;
  border-radius: 2px;
  border: 1px solid #dce9ff;
  margin: 4px;
  padding: 1px 4px;
  display: inline-block;
  min-width: 105px;
}

div button.highlighted-link,
div button.dashboard-link:hover {
  background-color: #d9f0e0;
  border: 1px solid #7fbf92;
  color: black;
}

.dashboard-icon {
  color: #7686aa;
}

.total {
  font-size: 3rem;
  font-weight: 900;
  line-height: 57px;
  vertical-align: middle;
}

.total-label {
  font-size: 1.25rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
}

.shadow-box {
  box-shadow: 0 3px 5px 3px #fafafa;
  border-radius: 4px;
}

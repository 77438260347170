@import "./Theme/custom";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");

div a {
  color: #4b5f9c;
}

td a {
  text-decoration: underline;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #7fbf92;
  background-color: #7fbf92;
}

.custom-control-label {
  font-weight: normal !important;
}

.container .custom-radio.custom-control {
  z-index: auto;
}

form .thirdTitle {
  font-size: 1.25rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #292f42;
}

form .input-border {
  border-color: #adc5de;
}

.row div .form-group label.font-weight-normal {
  font-weight: normal;
}
.blue {
  color: #1890ff;
}
.purple {
  color: #4b5f9c;
}
.dark-purple {
  color: #576293;
}
.black {
  color: #000;
}
.light-black {
  color: #212940;
}
.regular-line-height {
  line-height: 24px;
}

.settings a.link,
button .nav-link,
.font-weight-light a {
  font-weight: 300;
  text-decoration: underline;
  color: #3fa3ff;
}

button.nav-link.btn-secondary {
  color: #3fa3ff !important;
  font-weight: 300 !important;
}

div .ant-table-column-sorter-full {
  margin-top: -0.7em;
}

div .ant-select-selector {
  outline: 2px solid #c4c4c4;
  border: none;
}

div .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}

div .ant-table-thead > tr > th {
  background: rgba(173, 197, 222, 0.3);
  font-weight: bold;
}

div .ant-table-thead th.ant-table-column-has-sorters:hover {
  background: rgba(155, 188, 223, 0.3);
}

.ant-pagination-item a {
  font-weight: normal;
}

.ant-pagination-item a:horizontal {
  font-weight: bold;
}

.ant-pagination-item.ant-pagination-item-active a {
  font-weight: bold;
}

.blue-background {
  background: rgba(173, 197, 222, 0.3);
}

div .ant-table-tbody > tr > td {
  border-bottom: none;
}

div .ant-checkbox-inner {
  border: 2px solid #c4c4c4;
  border-radius: 4px;
}

div .ant-pagination-item,
div .ant-pagination-prev,
div .ant-pagination-next {
  outline: 2px solid #c4c4c4;
  border: none;
}

div li.ant-pagination-item-active {
  font-weight: bold;
}

li.ant-pagination-item:hover a {
  color: black;
  font-weight: bold;
}

div li.ant-pagination-item:hover {
  font-weight: bold;
  color: black;
}

div .anticon.anticon-right,
div .anticon.anticon-left {
  color: #4b5f9c;
}

div .ant-pagination-disabled .ant-pagination-item-link,
div .ant-pagination-disabled:hover .ant-pagination-item-link,
div .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  border-color: transparent;
}

div .ant-pagination-prev .ant-pagination-item-link,
div .ant-pagination-next .ant-pagination-item-link {
  border: transparent;
}

div .ant-pagination-disabled {
  outline-color: #eeeeee !important;
}

div .ant-table {
  color: black;
}

form div .ant-table tbody tr .ant-table-cell-row-hover .plusMessage {
  border-color: #fafafa !important;
  background-color: #fafafa !important;
}

div .ant-pagination-item-link {
  margin-top: -2px;
}

div .ant-table-thead th.ant-table-column-sort {
  background: #adc5de;
}

div div a.deactivated {
  color: #adc5de !important;
  cursor: default;
}

div .ant-table-column-sorter-inner {
  color: #4b5f9c;
}

form input.form-control.auto-width {
  width: auto;
}

.sticky-outer-wrapper {
  position: relative;
  z-index: 1;
}

.light-blue-color {
  color: #adc5de;
}

.visible {
  visibility: visible !important;
}

.shape {
  width: 16px !important;
  height: 16px !important;
  color: #7290b0;
}

.help-text-button {
  position: relative;
  margin-left: 0.25em;
}

.help-text-button .help-text {
  visibility: hidden;
  background: #fff;
  padding: 10px;
  z-index: 1;
  font-size: 10pt;
  line-height: 1.3;
  position: absolute;
  width: 200px;
  border-radius: 3px;
  bottom: 145%;
  box-shadow: 1px 1px 11px 2px #00000073;
  border: solid 1px #809bb8;
  background-color: #ffffff;
  color: #212940;
  font-weight: 400;
  left: 50%;
  transform: translate(-50%, 5%);
  -webkit-transform: translate(-50%, 5%);
}

.help-text-button .help-text.move-up {
  bottom: 195%;
}

.help-text-button:hover .help-text {
  visibility: visible;
}

a label {
  cursor: pointer;
}

.footer {
  bottom: 0;
  overflow: scroll;
  white-space: nowrap;
  line-height: 60px; /* Vertically center the text there */
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.arrow-button:disabled {
  background-color: white;
  color: #6c757d;
}

.pointer-cursor {
  cursor: pointer;
}

.pointer-default {
  cursor: default;
}

body
  div#hs-eu-cookie-confirmation
  div#hs-eu-cookie-confirmation-inner
  a#hs-eu-confirmation-button {
  background-color: #4b5f9c !important;
}

@media (max-width: 768px) {
  .help-text-button .help-text-left {
    margin-left: -60px;
  }
}
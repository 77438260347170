.loading-text {
  margin-bottom: 1rem;
  color: #687882;
  font-size: 0.9rem;
}

.court-loading-icon {
  position: absolute;
  margin: 0 auto;
  font-size: 1rem !important;
}

.loading-mask {
  background-color: #ffffff;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  .loading-indicator {
    opacity: 1;
  }
}

.animate-slide-in {
  animation: slide-in-left 0.2s ease;
}

.animate-slide-down {
  animation: slide-down 0.2s ease;
}

.animate-fade-in {
  animation: fade-in 0.2s ease;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

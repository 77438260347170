form.profile-approval tbody tr td:nth-child(2) {
  vertical-align: top;
}

form.profile-approval tbody tr.align-middle td:nth-child(2) {
  vertical-align: middle;
}

// !important overrides the .btn-secondary class which has !important
form.profile-approval .header .btn-secondary {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d;
}

// !important overrides the .btn-secondary class which has !important
form.profile-approval .header .active {
  background-color: #545b62 !important;
  border-color: #4e555b !important;
}

form.profile-approval table tbody tr:nth-child(2n) {
  background-color: rgba(196, 196, 196, 0.4); // Same as C4C4C4 - 40% opacity
}

form.profile-approval .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: rgba(196, 196, 196, 0.5); // Same as C4C4C4 - 50% opacity
}

.rdt_Pagination {
  align-items: center !important;
  justify-content: center !important;
}

form .form-check .custom-checkbox,
form .form-check .inline-display {
  display: inline-block;
  line-height: 0;
}
form .form-check .styled-checkbox.checked {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: #7fbf92;
  border-radius: 4px;
  transition: all 150ms;
  border: 2px solid #7fbf92;
}

form .form-check.light-font .text {
  font-size: 1rem;
  font-weight: 300;
  color: #212940;
}

form .form-check.light-font {
  padding-left: 0;
}

form .form-check .text {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #212940;
}
form .form-check .styled-checkbox {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: white;
  border-radius: 4px;
  transition: all 150ms;
  border: 2px solid #adc5de;
}
form .form-check .hidden-checkbox {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  box-sizing: unset;
}

form .form-check svg {
  visibility: hidden;
  fill: none;
  stroke: white;
  stroke-width: 2px;
}

form .form-check svg.displayCheck {
  visibility: visible;
}

form .form-check.break-spaces {
  white-space: break-spaces;
}

form .form-check.highlighted-text .text {
  color: #dd2a34;
}

form .form-check.highlighted-text .styled-checkbox {
  border-color: #dd2a34;
}

form .form-check.highlighted-text .styled-checkbox.checked {
  background: #dd2a34;
}

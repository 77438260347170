.billing form input,
.billing .stripe-input {
  border-radius: 4px;
  border: solid 2px #adc5de;
  background-color: #ffffff;
}

.billing input.form-control {
  font-size: 0.875rem;
  color: #79829f;
}

.billing input.form-control:disabled {
  background-color: #dadada;
}

.billing .stripe-input {
  padding: 9px 11px 11px 15px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.billing .stripe-input > div {
  width: 90%;
}

.billing .stripe-input.disabled {
  background-color: #dadada;
}

.billing .stripe-input > svg {
  margin-right: 10px;
}

.billing form input.highlighted,
.billing form div.highlighted {
  background-color: #f8d7da;
}

.billing .promo-row button {
  height: auto;
}

input::-webkit-input-placeholder {
  font-style: normal;
}

input::-ms-input-placeholder {
  font-style: normal;
}

input:-ms-input-placeholder {
  font-style: normal;
}

input::-moz-placeholder {
  font-style: normal;
}

input:-moz-placeholder {
  font-style: normal;
}

input::placeholder {
  font-style: normal;
}

.billing .info-value.small-font,
.billing .info.small-font,
.small-font {
  font-size: 0.875rem !important;
}

.large-font {
  font-size: 1.25rem;
}

.medium-font {
  font-size: 1.125rem;
}

.billing button {
  height: 48px;
}

.billing .info {
  color: #4b5f9c;
  font-size: 1.1875rem;
}

.billing .info-value {
  font-size: 1.1875rem;
}

.billing .dash-line {
  border-top: 2px solid #e3e3e3;
}

.billing .large-margin {
  margin-right: -25px;
  margin-left: -25px;
}

.billing form .row a.nav-link:hover,
.billing form .row a.nav-link:focus,
.billing form .row a.nav-link:active {
  color: #1890ff;
}

.billing form .row a.nav-link,
.billing form .row a.nav-link,
.billing form .row a.nav-link {
  color: rgba(0, 0, 0, 0.85);
}

.billing form .highlighted.selectControl {
  border: none !important;
}

.billing form .selectControl > div:first-child:hover {
  border-color: #adc5de;
}

/* spinner/processing state, errors */
.checkout-spinner,
.checkout-spinner:before,
.checkout-spinner:after {
  border-radius: 50%;
}
.checkout-spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.checkout-spinner:before,
.checkout-spinner:after {
  position: absolute;
  content: "";
}
.checkout-spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #4b5f9c;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.checkout-spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #4b5f9c;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

.checkout-spinner:hover:before,
.checkout-spinner:hover:after,
.checkout-spinner:before:hover,
.checkout-spinner:after:hover {
  background: #546bb3;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.product-search {
  .form-control {
    padding: 1.25rem 1rem;
  }
  .form-check {
    padding-left: 0;
  }
  .input-group {
    .blue-shade-background {
      background-color: #4b5f9c !important;
      color: white !important;
    }
    .border-white:not(:hover) {
      border-color: white !important;
    }
  }
}

.expand-input {
  width: calc(100% - 200px);
}

.rdt_TableHeadRow {
  font-size: 14px;
  font-weight: bold;
}

.rdt_TableCell > div, .rdt_TableCell > a {
  font-size: 14px;
}

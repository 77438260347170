/* Identity */

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.btn-primary {
  color: #fff !important;
  background-color: #1b6ec2 !important;
  border-color: #1861ac !important;
}

button.btn.btn-ok {
  width: 100%;
  background-color: #4b5f9c;
}

.text-underline {
  text-decoration: underline;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.btn-link.nav-link:focus {
  outline: black auto 1px;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.large-container {
  max-width: 1278px;
  margin-left: auto;
  margin-right: auto;
}
// .container {
//   max-width: 960px;
// }

.pricing-header {
  max-width: 700px;
}

.border-top {
  border-top: 1px solid #e5e5e5;
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

button.accept-policy {
  font-size: 1rem;
  line-height: inherit;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

/* Custom styles */
.body-container {
  margin-top: 60px;
  padding-bottom: 40px;
}

.welcome-page li {
  list-style: none;
  padding: 4px;
}

.logged-out-page iframe {
  display: none;
  width: 0;
  height: 0;
}

.grants-page .card {
  margin-top: 20px;
  border-bottom: 1px solid lightgray;
}
.grants-page .card .card-title {
  font-size: 120%;
  font-weight: bold;
}
.grants-page .card .card-title img {
  width: 100px;
  height: 100px;
}
.grants-page .card label {
  font-weight: bold;
}

.large-title {
  color: #212940;
  font-size: 1.75rem;
  font-weight: 500;
}

.light-blue {
  background-color: #ecf1f7;
}

.dark-blue {
  background-color: #253b7d;
}
.dusky-blue {
  background-color: #4b5f9c;
}

.dusky-blue-color {
  color: #4b5f9c;
}

.blue-color {
  color: #4682f5;
}

.dark-color {
  color: #292f42;
}

.opacity-3 {
  opacity: 0.3;
}

div button.btn.external-provider {
  width: 100%;
  border-radius: 4px;
  border: solid 2px #c1c9ef;
  background-color: #f8faff;
  font-size: 0.875rem;
}

.or-line {
  border-right: 1px solid #c8d3f4;
}
.grey-text {
  color: #79829f;
}

.row div .form-group label {
  font-size: 1rem;
  font-weight: normal;
}

.form-group > input {
  height: 39px;
  padding: 9px 11px 11px 15px;
  border-radius: 4px;
  border: solid 2px #adc5de;
}

.form-group > div > input.number {
  height: 39px;
  padding: 9px 11px 15px 15px;
  border-radius: 4px;
  border: solid 2px #adc5de;
}

/* Progress bar */
#progress-wrapper {
  background-color: #f3f8fc;
  margin-bottom: 1rem;
  padding-top: 2.1rem;
  padding-left: 10%;
  padding-bottom: 1rem;
  position: relative;
  height: 7.5rem;
}

#progress-wrapper .check-circle {
  width:75%;
}

.progress-control {
    display: flex;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

#progress-wrapper .progress-control {
    width: 90%;
    height: 0.2rem;
    overflow: visible;
}

#progress-wrapper .step {
    position: absolute;
    top: 1.5rem;
    text-align: center;
    width: 7rem;
    margin-left: -2.5rem;
}

#progress-wrapper .step-1 {
    left: 10%
}

#progress-wrapper .step-2 {
    left: 51%;
}

#progress-wrapper .step-3 {
    left: 91%;
}

#progress-wrapper .progress-bar {
  background: rgb(108, 213, 165);
}

#progress-wrapper .progress-1 {
  width: 0%;
}

#progress-wrapper .progress-2 {
  width: 51%;
}

#progress-wrapper .progress-3 {
  width: 100%;
}

#progress-wrapper .circle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    margin-left: 2.5rem;
    margin-bottom: 0.5rem;
}

#progress-wrapper div.circle.current-step {
    background: #009eed;
    color: #009eed;
}

#progress-wrapper .uncompleted-step {
    border: 2px solid #adc5de;
    color: #009eed;
    background: #f3f8fc;
}

#progress-wrapper .completed-step {
    background: rgb(108, 213, 165);
    color: #ffffff;
    padding-top: 0.2rem;
}

#progress-wrapper .step-header {
    font-size: 0.875rem;
    color: #5f6780;
    text-transform: uppercase;
}

#progress-wrapper .current-step {
    color: #5388eb !important;
    font-weight: bold !important;
}

#progress-wrapper .step-desc {
    font-size: 0.875rem;
    color: #5f6780;
}

footer {
  border-top: solid 1px #dce9ff;
}

.footer-about {
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #21296B;
  line-height: normal;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
}

.footer-logo {
  width: 250px;
  height: 40px;
}

.footer-therapist-date {
  float: right;
  font-family: Lato;
  font-size: .75rem;
  margin-top: 12px;
  text-align: center;
  white-space: nowrap;
  color: #21296B;
}

.footer-nav {
  margin-top: 7px;
}

.footer-container {
  padding: 30px 0 !important;
}

.public-header {
  height: 80px;
  background-color: #EDF1F7;
  border-bottom: solid 1px #dce9ff;
}

.full-width {
  width: 100%;
}

#root {
  height: 100%;
}

div.large-padding {
  padding-bottom: 100px;
}

.sub-root {
  position: relative;
  min-height: 100%;
  height: auto !important;
}

a.navbar-brand {
  height: 72px;
  padding-top: 12px;
  padding-bottom: 10px;
  margin: 0 auto;
}

a img.logo {
  height: 100%;
  width: auto;
}

.navbar .larger-container a.navbar-brand {
  height: 64px;
  font-size: 1.375rem;
  letter-spacing: 1.2px;
  color: #253b7d;
  font-weight: 500;
}

.main nav.navbar {
  padding-right: 0.5rem;
}

header nav.navbar {
  padding-top: 0;
  padding-right: 0.5rem;
}

@media only screen and (min-width: 992px) and (max-width: 1400px) {
  a.navbar-brand {
    height: 50px;
  }
}

@media only screen and (max-width: 991px) {
  footer {
    height: 165px;
  }
  div.large-padding {
    padding-bottom: 180px;
  }
  #progress-wrapper .step {
    width: 6rem;
  }
}

@media only screen and (max-width: 768px) {
  header nav.navbar {
    justify-content: center;
    -webkit-justify-content: center;
  }
  footer > div > div {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .footer-therapist-date {
    float: none;
    text-align: center;
    margin-top: 20px;
  }
}

@media only screen and (max-width : 480px) {
    #progress-wrapper .progress-control {
        width: 86%;
    }

    #progress-wrapper .step-1 {
        left: 10%
    }

    #progress-wrapper .step-2 {
        left: 50%;
    }

    #progress-wrapper .step-3 {
        left: 85%;
    }
}

.footer-logo img {
  height: 3.25em;
}

form textarea.three-rows {
  height: 89px;
}

.btn-primary.disabled.green-btn,
.btn-primary.green-btn:disabled {
  background-color: #7fbf92 !important;
  border-color: #7fbf92 !important;
  color: white !important;
}

form button.green-btn {
  background-color: #76ca8e !important;
  border-color: #76ca8e !important;
  color: white !important;
}

form .green-btn:hover {
  background-color: #73cd8e !important;
  border-color: #73cd8e !important;
  color: white !important;
}

hr.blue-background {
  background-color: #adc5de;
}

.text-break {
  white-space: pre-wrap;
}

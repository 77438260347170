.date-label {
  min-width: 48px;
}

.time-label {
  width: 150px;
}

div .copy {
  cursor: pointer;
}

.rdt_TableCell {
  .assignProduct {
    padding-bottom: 0.5rem !important;
    cursor: pointer;
  }

  a {
    text-decoration: underline;
  }
}

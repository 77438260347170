form .timeInput {
  width: 70px;
  padding: 7px 14px 7px 15px;
  border-radius: 4px;
  border: solid 2px #c8cdd3;
  font-size: 0.875rem;
}

form .businessHours .selectControl {
  width: 55px;
}

form .trashCan {
  color: #a7a9ac !important;
  border-color: white !important;
  background-color: white !important;
}

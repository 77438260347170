form.profile-search table tbody tr:nth-child(2n) {
  background-color: rgb(232, 232, 232);
}

form.profile-search .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: rgb(204, 204, 204);
}

.ant-table-column-sort {
  background-color: inherit !important;
}

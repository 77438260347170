.tab-content .container {
  margin-left: 0;
  margin-right: 0;
}
.provider-form {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}
form {
  font-family: Lato;
}

form .subTitle {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
}

form .form-control {
  border: solid 2px #adc5de;
}

form .form-control-cost {
  display: inline;
  width: 80px;
  text-align: center;
}

form .box {
  border: solid 2px #4b5f9c;
  background-color: #ffffff;
}
form .row .nav-tabs li.nav-item {
  margin-left: 0;
}
.border-filler {
  display: none;
}
.nav-tabs .nav-item .nav-link, .nav-link.active {
  font-size: 1rem;
  color: #007bff;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  font-weight: bold;
}

form .btn-info-toggle {
  font-size: 1rem;
  padding-top: 0;
}

@media (min-width: 1140px) {
  form .row .nav-tabs li.nav-item.active {
    position: relative;
    border: solid 2px #4b5f9c;
    border-bottom: solid 2px #ffffff;
    border-radius: 0.25rem;
    height: 49px;
    background-color: #ffffff;
    border-bottom-left-radius: unset;
    z-index: 1;
  }
  .nav-tabs .nav-item .nav-link, .nav-link.active {
    margin-left: 10px;
    margin-right: 10px;
    color: #000000;
  }
  .nav-tabs .nav-item .nav-link-first {
    margin-left: 10px;
  }
  .profile-edit-tab {
    border-radius: 0.25rem;
  }
  .profile-edit-tab {
    position: relative;
    top: -10px;
  }
  //border-filler is used to fill pixel gap between first tab and tab content
  .border-filler {
    display: block;
    position: absolute;
    z-index: 2;
    height: 10px;
    top: 40px;
    left: 0;
    width: 30px;
    background-color: #ffffff;
    border-left: solid 2px #4b5f9c;
  }
}
@media (min-width: 1410px) {
  .nav-tabs .nav-item .nav-link {
    font-size: 1.25rem;
  }
}

.profile-image-wrapper img {
  width: 181px;
  height: 181px;
}

.profile-image-wrapper {
  min-height: 184px;
  width: 184px;
  height: 184px;
  border: #adc5de 2px solid;
}

// Had to remove these because the admin screen to edit a provider profile showed the reset button larger than the other buttons
// form a.btn {
//   height: 48px;
//   padding-top: 10px;
// }

.input-field-icons svg {
  position: absolute;
  color: #4f629e;
  width: 24px !important;
  height: 24px;
}

form .help-text-button svg {
  position: absolute;
  color: #4f629e;
  width: 12px !important;
  height: 12px;
}

form .input-field-icons .relative svg,
form .help-text-button.relative svg {
  position: relative;
  color: #4b5f9c;
}

form .help-text-button.relative svg {
  color: #212529;
}

form .help-text-button.relative.move-icon svg {
  position: relative;
  color: #4b5f9c;
  width: 12px !important;
  height: 12px;
  top: -3px;
}

@media (max-width: 280px) {
  form .gmaps-image {
    max-width: 100%;
  }
}

form .gmaps-image {
  width: 280px;
  height: 198px;
}

form .form-group > label,
form .form-group div > label:not(.custom-control-label),
.blue-border .form-check-label {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #212940;
}

form .form-group div > label.custom-control-label {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #212940;
}

form .form-group .form-check.custom-radio-control {
  padding-left: 0;
}

form .character-limit {
  font-family: Lato;
  font-size: 1rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #000000;
  white-space: pre-wrap;
}

form .character-limit.red {
  color: #ec755a;
}

form .character-limit.rates {
  font-weight: 400;
  opacity: 0.6;
}

div .image-editor .update-button {
  font-weight: 400 !important;
}

form .custom-file-upload,
.edit-image-modal .custom-file-upload,
.image-editor .update-button {
  display: inline-block;
  cursor: pointer;
  border: solid 2px #4b5f9c;
  padding: 0.3rem 1.25rem 0.3rem 1rem;
  background-color: white;
  font-weight: 300 !important;
  font-size: 1.25rem;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #4b5f9c !important;
}
form .custom-file-upload:hover,
.edit-profile-modal .custom-file-upload:hover {
  color: #4b5f9c;
  border: solid 2px #4b5f9c;
  background-color: #f6f7f8;
}

input[type="file"] {
  display: none;
}

.nav-item {
  cursor: pointer;
}

form .highlighted {
  background-color: #f8d7da !important;
  border: solid 2px #dd2a34 !important;
}

form .highlighted.selectControl {
  border-radius: 6px;
}

table.assignment .empty td {
  border-radius: 6px;
  border: dashed 1px #adc5de;
}

.required-field {
  color: #212940;
}

.edit-profile-modal .highlighted-text,
form .form-group > label.highlighted-text,
form .form-group > div.highlighted-text,
form .form-group > div > label.highlighted-text,
form .form-group .row div .highlighted-text,
form .form-group label span.highlighted-text,
form label.highlighted-text,
form div.highlighted-text,
form .thumb div .highlighted-text form .form-group span.highlighted-text,
div div ul.nav.nav-tabs li a.highlighted-text {
  color: #dd2a34;
}

form .form-group.highlighted-text,
form .form-group .row div .highlighted-text {
  margin-bottom: 0;
}

form .highlighted.selectControl > div {
  background-color: #f8d7da;
}

.icon-indicator {
  font-size: 1rem;
  font-weight: normal;
}
.icon-indicator .required-field-indicator {
  font-size: 0.675rem;
  padding-bottom: 3px;
}

#drag-handles > div {
  cursor: initial;
}
.handle {
  cursor: move;
  top: 3px;
}

.form-check-label {
  font-weight: normal !important;
}

.small-font {
  font-size: 0.875rem;
}
.normal-font {
  font-size: 1rem;
}

.gray-text {
  color: #5e6884;
}

.blue-1 {
  background-color: #f1f8ff;
}

.blue-2 {
  color: #5388eb;
}

.blue-border {
  border-left: 2px solid #5388eb;
}

.hide-font {
  font-size: 0.3rem;
}

::-webkit-input-placeholder {
  font-style: italic;
  font-size: 0.875rem;
}
:-moz-placeholder {
  font-style: italic;
  font-size: 0.875rem;
}
::-moz-placeholder {
  font-style: italic;
  font-size: 0.875rem;
}
:-ms-input-placeholder {
  font-style: italic;
  font-size: 0.875rem;
}

.white-radial-gradient {
  background: radial-gradient(at center, white 50%, transparent 50%);
}

.flex-previews {
  display: flex;
  flex-direction: "column" as "column";
}

.separator {
  width: 20px;
}

.officeImg {
  max-width: 100%;
  width: auto;
  height: 136px;
}

.drop-base-style {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 10px;
  border-color: #adc5de;
  border-style: dashed;
  color: #292f42;
  outline: none;
  justify-content: center;
  height: 100%;
  transition: border 0.24s ease-in-out;
}
.googlePlaces > div:first-child {
  border: solid 2px #adc5de;
}

.office-images .container {
  max-width: 100% !important;
}

.no-border {
  border: none;
  background: none;
}
.no-border:focus {
  background: none;
  outline: none;
}

form .plusMessage {
  color: #4b5f9c !important;
  border-color: white !important;
  background-color: white !important;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 300;
}

form .row a.plusMessage.nav-link {
  font-weight: 300;
}

form .small-width {
  flex-basis: 1% !important;
}

.wrap-around .wrap-left {
  margin-left: 7px;
}
.wrap-around .wrap-left-negative {
  margin-left: -25px;
}

form .li-textbox-row {
  margin-left: -25px;
  margin-right: 25px;
}

  .profile-edit-nav {
    margin-left: 1rem;
  }
  .profile-edit-tab {
    padding-right: .5rem;
    padding-left: .5rem;
    margin-left: 0;
    margin-left: 0;
  }

.profile-edit-tab textarea {
  border-radius: 0.7rem;
}
.profile-checkbox-item {
  margin-top: 0.25rem;
}
.profile-checkbox-group {
  display: block;
}

@media (max-width: 991px) {
  .drag-handles .handle {
    display:none;
  }
  .profile-checkbox-group-hide-mobile {
    display:none;
  }
  .profile-checkbox-hide-mobile {
    display:none;
  }
  .drop-base-style {
    border: none;
    padding: 10px;
  }
}

// Small devices (landscape phones)
@media (min-width: 1px) {

  form .column-sort ul {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    list-style-type: none;
    padding-inline-start: 0;
  }
  form .to-field {
    padding-left: 16px !important;
  }
}

@media (min-width: 768px) {
  form .to-field {
    // same as pl-1
    padding-left: 0.25rem !important;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .provider-form {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  form .column-sort ul {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    list-style-type: none;
    padding-inline-start: 0;
  }
  form .column-sort-sub ul {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  form .text-input-container {
    display: block;
    height: 40px;
  }
  .profile-checkbox-item {
    margin-top: -0.4rem;
  }
  .checkbox-group-toggle {
    display:none;
  }
    .profile-edit-nav {
    margin-left: 1rem;
  }

  .profile-edit-tab {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .profile-edit-nav {
    margin-left: 0;
  }
  .profile-checkbox-item {
    margin-right: -1.5rem;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  form .column-sort ul {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    list-style-type: none;
    padding-inline-start: 0;
  }
  form .column-sort-sub ul {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}
